html {
  scroll-behavior: auto !important;
}

/*fonts*/
@font-face {
  font-family: 'bignoodletitling';
  src: url(fonts/bignoodletitling.ttf);
}

@font-face {
  font-family: 'galvji';
  src: url(fonts/galvji.ttf);
}

@font-face {
  font-family: 'gothambold';
  src: url(fonts/gothambold.otf);
}

@font-face {
  font-family: 'geflowbold';
  src: url(fonts/geflowbold.otf);
}

@font-face {
  font-family: 'gesstextMedium';
  src: url(fonts/gesstextMedium.otf);
}

.ff-gothambold {
  font-family: 'gothambold' !important;
}

.ff-galvji {
  font-family: 'galvji' !important;
}

.ff-bignoodletitling {
  font-family: 'bignoodletitling' !important;
}

.ff-geflowbold {
  font-family: 'geflowbold' !important;
}

.ff-gesstextMedium {
  font-family: 'gesstextMedium' !important;
}

.primary-bg {
  background-color: #b31622 !important;
}

.transparent-bg {
  background-color: #fff0 !important;
}

.black-bg {
  background-color: #000 !important;
}

.fs-8 {
  font-size: .7rem;
}

.scroll-mt {
  scroll-margin-top: 180px;
}

/* .pt-scrollspy{
  scroll-padding-top: 300px; 
} */
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  /* or use 'block' if necessary */
}

.background-image {
  display: block;
  width: 100%;
  /* Adjust as needed */
  height: auto;
  /* Maintain aspect ratio */
}

.top-banner {
  max-height: 300px;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));
  /* Adjust colors and opacity as needed */
}

.overlay-image {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Adjust the size of the overlay image as needed */
  /* width: 150px; */
}

#dropdown-language {
  background-color: unset !important;
  border: unset !important;
}

.dropdown-img {
  width: 50px;
  height: 40px;
}

.dropdown-toggle::after {
  display: none !important;
}

.feedback-icon {
  width: 30px !important;
  height: 30px !important;
  -webkit-filter: brightness(0) invert(1) grayscale(1);
  filter: brightness(0) invert(1) grayscale(1);
}

.h-scroll {
  overflow-x: auto;
  flex-wrap: nowrap !important;
  scrollbar-width: thin;
}

.h-nav2 {
  height: 55px;
}

.nav-btn {
  width: 25px;
}

.body {
  background-image: url("../public/assets/background.webp");
  margin-top: -15px;
}

.list-group-item {
  background-color: #fff0 !important;
  border: unset !important;
  color: #fff !important;
}

.navbar2 .col {
  padding-left: 0;
  padding-right: 0;
  line-height: 25px;
  white-space: nowrap;
}

.navbar2 .col:first-child:before {
  padding-left: 0px;
  content: none;
}

.navbar2 .col::before {
  content: "•";
  position: relative;
  left: 0;
  color: #b31622;
  margin-left: 10px;
}

.active-nav1,
.active-scroll-spy {
  color: #EADE32 !important;
}

.card-title {
  color: #b31622 !important;
  white-space: nowrap !important;
  font-size: 1.1rem !important;
}

.vr {
  border-left: 1px solid #fff;
  height: 35px;
  margin-top: 5px !important;
  opacity: 0.6 !important;
  background-color: unset !important;
  padding: 0px !important;
}

.h-product-card {
  min-height: 495px;
}

.btn-scroll-top {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 50px;
  height: 50px;
  color: #fff;
  z-index: 999;
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: #b31622;
  --bs-btn-border-color: #b31622;
  --bs-btn-hover-color: #b31622;
  --bs-btn-hover-bg: #b31622;
  --bs-btn-hover-border-color: #b31622;
  --bs-btn-active-color: #b31622;
  --bs-btn-active-bg: #b31622;
  --bs-btn-active-border-color: #b31622;
}

.btn-scroll-top img {
  width: 25px;
}

.alert-nav1 {
  height: 55px !important;
}

.topN2-55 {
  top: 55px !important;
}

.d-mobile {
  display: none;
}

.p-icon {
  padding: 2px;
}

.text-cal{
  font-size: 10px;
}

.text-allergens {
  font-family: 'galvji';
  color: #FFFFFF;
  font-size: 18px;
  text-align: center;
}

.card-text {
  white-space: pre-line;
}

@media only screen and (max-width: 450px) {
  .h-product-card {
    min-height: 320px;
  }

  .h-nav2 {
    height: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .h-product-card {
    min-height: 325px;
  }

  .card-text {
    font-size: 0.6rem !important;
  }

  .text-allergens {
    font-family: 'galvji';
    color: #FFFFFF;
    font-size: 0.8rem;
    text-align: center;
  }

  .d-desktop {
    display: none;
  }

  .d-mobile {
    display: block;
  }
}